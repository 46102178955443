import React from 'react';
import styled from 'styled-components';
import CheckIcon from '../../assets/check.svg';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  p {
    color: #556685;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    padding-left: 12px;
    margin: 0;
  }
`;

const ItemIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5A55AB;
  width: 28px;
  height: 28px;
  border-radius: 15px;
  box-shadow: 0px 1px 4px RGBA(0, 8, 32, 0.12), 0px 4px 8px RGBA(0, 8, 32, 0.08);
`;

const ExtraItem = ({ text }) => {
  return (
    <ItemWrapper>
      <ItemIcon>
        <img src={CheckIcon} alt="icon" />
      </ItemIcon>
      <p>{text}</p>
    </ItemWrapper>
  );
};

export default ExtraItem;
