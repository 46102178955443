import React from 'react'
import styled from 'styled-components'

import Ribbon from './Ribbon'

import Clock from '../../assets/clock.svg'
import Lock from '../../assets/lock.svg'

const CardContainer = styled.div`
  margin: 15px 42px 32px;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.12), 0px 4px 8px rgba(0, 8, 32, 0.08);
  border-radius: 4px;
  position: relative;
  & > img {
    max-width: 332px;
    object-fit: contain;
    height: 100%;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  width: 100%;
  button {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-weight: bold;
    color: white;
    padding: 8px 22px;
    border-radius: 100px;
    outline: none;
    border: none;
    background: #5a55ab;
    font-family: 'Poppins', sans-serif;
  }
`

const CardTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #152540;
  margin-top: 20px;
  display: flex;
`

const CardDescription = styled.div`
  color: #556685;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
  margin: 16px 0;
  width: 100%;
  padding: 10px 20px 10px 20px;
  background: #f3f3f3;
  p {
    margin: 0px;
  }
`

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`

const CardTags = styled.div`
  display: flex;
`

const CardDuration = styled.span`
  color: #556685;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  img {
    padding-right: 6px;
  }
`

const CardSource = styled.div`
  display: flex;
  line-height: 22px;
  font-weight: bold;
  font-size: 12px;
  color: #645a53;
  img {
    max-height: 30px;
    margin-right: 5px;
  }
  @media (max-width: 512px) {
    display: none;
  }
`

const LockButton = styled.div`
  background: #5a55ab;
  padding: 6px;
  line-height: 0px;
  width: 26px;
  height: 26px;
  border-radius: 15px;
  margin-right: 8px;
`

const StepCard = ({
  title,
  note,
  price,
  duration,
  thumbnailSrc,
  type,
  source,
  isblur,
}) => (
  <CardContainer>
    <CardBody>
      <CardHeader>
        <CardTags>
          <Ribbon
            text={type}
            customStyle={{
              padding: '4px 6px',
              fontWeight: 'bold',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#BF7817',
              background: '#FFECD0',
              marginRight: '16px',
              borderRadius: '4px',
            }}
          />
          <Ribbon
            text={
              price && price.value !== 0
                ? `${price.currency} ${price.value}`
                : 'FREE'
            }
            customStyle={{
              padding: '4px 6px',
              fontWeight: 'bold',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#128945',
              border: '1px solid #D1F2E1',
              marginRight: '16px',
              borderRadius: '4px',
            }}
          />
          {duration && (
            <CardDuration>
              <img src={Clock} alt="duration" />
              {duration}
            </CardDuration>
          )}
        </CardTags>
        <CardSource>
          {source.iconSrc ? (
            <img src={source.iconSrc} alt="source" />
          ) : (
            source.info?.name || ''
          )}
        </CardSource>
      </CardHeader>
      <CardTitle>
        <LockButton>
          <img src={Lock} alt="Lock Button" />
        </LockButton>
        <span
          style={{
            opacity: isblur ? 0.5 : 1,
            filter: isblur ? 'blur(8px)' : 'none',
          }}
        >
          {isblur
            ? "This title is protected. So you can't copy this title."
            : title}
        </span>
      </CardTitle>
      {note && (
        <CardDescription
          dangerouslySetInnerHTML={{
            __html: note,
          }}
        />
      )}
    </CardBody>
  </CardContainer>
)

export default StepCard
