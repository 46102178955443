/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled from 'styled-components'
import pluralize from 'pluralize'
import { Link } from 'gatsby'
import Ribbon from '../components/learningPaths/Ribbon'
import ExtraItem from '../components/learningPaths/ExtraItem'
import StepCard from '../components/learningPaths/StepCard'
import SEO from '../components/Seo'

import Clock from '../assets/clock.svg'
import Pattern from '../assets/pattern.svg'
import Unlock from '../assets/unlock.svg'

const baseURL = (ref) => `https://app.innential.com/register?ref=${ref}&path=`

const PathsList = ({ pageContext: { data } }) => {
  const [showMore, setShowMore] = useState(false)

  const safeWindow = typeof window !== 'undefined' ? window : {}

  const params = new URLSearchParams(safeWindow?.location?.search)

  // const fromUseCase = params.get('uc')
  // const fromLibrary = params.get('library')

  // const backTo = fromUseCase
  //   ? '/use-cases/continuous-education'
  //   : fromLibrary
  //   ? '/virtual-library/paths'
  //   : '/learning-paths'

  const ref = params.get('ref') || 'lp'
  const backTo = params.get('backto') || '/learning-paths'

  const goalTemplate = data.goalTemplate.map((el) => {
    const lessons = {}
    el.content.forEach((content) => {
      if (!content.source.info) return
      if (!lessons[content.source.info.name]) {
        lessons[content.source.info.name] = {
          value: 1,
          icon: content.source.iconSrc,
        }
      } else {
        lessons[content.source.info.name] = {
          ...lessons[content.source.info.name],
          value: lessons[content.source.info.name].value + 1,
        }
      }
    })
    return {
      ...el,
      lessons,
    }
  })

  const trackClick = () => {
    if (typeof safeWindow.gtag === 'function') {
      safeWindow.gtag('event', 'sign_up_path', {
        event_category: 'LEARN',
        event_label: data.name,
      })
    }
  }

  const claimLearningPath = () => {
    trackClick()
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = `${baseURL(ref)}${data._id}`
    a.click()
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      <SEO
        title={`Learning path - ${data?.name}`}
        description={data?.abstract}
      />
      <PageHeader>
        <Container>
          <Backlink to={backTo}>Back</Backlink>
        </Container>
      </PageHeader>
      <Container>
        <Pathpanel>
          <Infopanel>
            <Authorinfo>
              <Tags>
                <Ribbon
                  text="LEARNING PATH"
                  customStyle={{
                    background: '#E7F9F7',
                    borderRadius: '2px',
                    padding: '6px 12px',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#29A399',
                    height: 'fit-content',
                  }}
                />
                {data.duration && (
                  <Duration>
                    <img src={Clock} alt="duration" />
                    {data.duration}
                  </Duration>
                )}
              </Tags>
              {data.author && (
                <Authoravatar>
                  <Information>
                    <span>Curated by</span>
                    <span>{data.author}</span>
                  </Information>
                </Authoravatar>
              )}
            </Authorinfo>
            <InfoPanelTitle>{data.name}</InfoPanelTitle>
            <InfoPanelDescription
              className={showMore ? 'read-more' : 'limit'}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <ShowMoreButton onClick={() => setShowMore(!showMore)}>
              {!showMore && (
                <>
                  Read More
                  <i className="icon icon-small-right">{'>'}</i>
                </>
              )}
              {showMore && <>Hide Description</>}
            </ShowMoreButton>
            {data.startConditions && data.startConditions.length > 0 && (
              <ExtraInfo>
                <h2>Start this path if:</h2>
                {data.startConditions.map((cond) => (
                  <ExtraItem text={cond} />
                ))}
              </ExtraInfo>
            )}
            {data.skills.length > 0 && (
              <SkillSets>
                <SkillSetsTitle>Skills you will learn</SkillSetsTitle>
                <SkillSetsBody>
                  {data.skills.map((el, index) => (
                    <Ribbon
                      key={`skill-ribbon-${index}`}
                      text={el.name}
                      customStyle={{
                        background: '#F9F8FC',
                        borderRadius: '4px',
                        padding: '8px 15px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#5A55AB',
                        height: 'fit-content',
                        marginBottom: '5px',
                      }}
                    />
                  ))}
                </SkillSetsBody>
              </SkillSets>
            )}
          </Infopanel>
        </Pathpanel>
        <BackgroundImg src={data.bannerSrc} alt="background" />
      </Container>
      <GoalSteps>
        {goalTemplate.map((el, index) => (
          <StepContainer key={`goal-template-${index}`}>
            <StepTitle>
              <StepTitleIcon>
                <span>goal</span>
                <span>{index + 1}</span>
                <img src={Pattern} alt="pattern" />
              </StepTitleIcon>
              <h2>{el.goalName}</h2>
            </StepTitle>
            {index === 0 ? (
              <>
                {el.content.slice(0, 2).map((content, idx) => (
                  <StepCard
                    key={`goal-content-${idx}`}
                    {...content}
                    isblur={idx !== 0}
                  />
                ))}
                {el.content.length > 2 && Object.keys(el.lessons).length > 0 && (
                  <AbbrDesc>
                    <strong>Includes </strong>
                    {Object.keys(el.lessons).map((lesson, idx) => {
                      const content = el.lessons[lesson]
                      return (
                        <span>
                          <p>
                            {idx === 0
                              ? ''
                              : idx === Object.keys(el.lessons).length - 1
                              ? ', and'
                              : ' ,'}{' '}
                            {pluralize('lesson', content.value, true)} from{' '}
                          </p>
                          {content.icon ? (
                            <img src={content.icon} alt="Icon" />
                          ) : (
                            <strong>{lesson}</strong>
                          )}
                        </span>
                      )
                    })}
                  </AbbrDesc>
                )}
              </>
            ) : (
              Object.keys(el.lessons).length > 0 && (
                <AbbrDesc>
                  <strong>Includes </strong>
                  {Object.keys(el.lessons).map((lesson, idx) => {
                    const content = el.lessons[lesson]
                    return (
                      <span>
                        <p>
                          {idx === 0
                            ? ''
                            : idx === Object.keys(el.lessons).length - 1
                            ? ', and'
                            : ' ,'}{' '}
                          {pluralize('lesson', content.value, true)} from{' '}
                        </p>
                        {content.icon ? (
                          <img src={content.icon} alt="Icon" />
                        ) : (
                          <strong>{lesson}</strong>
                        )}
                      </span>
                    )
                  })}
                </AbbrDesc>
              )
            )}
          </StepContainer>
        ))}
      </GoalSteps>
      <PageFooter>
        <UnlockButton onClick={() => claimLearningPath()}>
          <ShineDiv />
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 7C8 5.96596 8.34992 4.96141 9.0038 4.22795C9.64176 3.51236 10.6159 3 12 3C13.3542 3 14.3146 3.49044 14.9519 4.17914C15.327 4.58452 15.9596 4.60909 16.365 4.23401C16.7704 3.85893 16.795 3.22624 16.4199 2.82086C15.3867 1.70421 13.8854 1 12 1C10.0704 1 8.54453 1.73764 7.51092 2.89705C6.49323 4.03859 6 5.53404 6 7L6 10H5C3.34315 10 2 11.3431 2 13V20C2 21.6569 3.34315 23 5 23H19C20.6569 23 22 21.6569 22 20V13C22 11.3431 20.6569 10 19 10H8L8 7ZM7 12H5C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H7Z"
              fill="white"
            />
          </svg>
          Sign up to unlock path
        </UnlockButton>
      </PageFooter>
    </div>
  )
}

const AbbrDesc = styled.div`
  padding: 20px 11px;
  margin-left: 46px;
  margin-right: 40px;
  color: #556685;
  background: rgba(239, 238, 247, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  strong {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 0 5px;
  }
  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    p {
      white-space: nowrap;
      line-height: 22px;
      font-weight: normal;
    }
  }
  img {
    max-height: 20px;
    margin: 0 5px;
    width: unset;
  }
`

const PageHeader = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 32px 0;
`

const PageFooter = styled.div`
  background-color: white;
  padding: 20px;
  text-align: center;
  z-index: 1;
  position: fixed;
  width: 100%;
  top: auto;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  box-shadow: 0px -1px 4px RGBA(0, 8, 32, 0.04),
    0px -16px 32px RGBA(0, 8, 32, 0.04);
`
const ShineDiv = styled.div`
  width: 309px;
  height: 58px;
  position: absolute;
  border: 4px solid RGBA(90, 85, 171, 0.5);
  border-radius: 100px;
  box-sizing: border-box;
  top: -6px;
  left: -6px;
  -webkit-animation-name: shine90;
  animation-name: shine90;
  -webkit-animation-duration: 1400ms;
  animation-duration: 1400ms;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;

  @keyframes shine90 {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    25% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0;
      transform: scale(1.05);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
`

const UnlockButton = styled.button`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  padding: 10px 40px;
  border-radius: 100px;
  background-color: #5a55ab;
  border: 2px solid #5a55ab;
  outline: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  svg {
    margin-right: 10px;
  }
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #5a55ab;
    border-color: #fff;
    color: #5a55ab !important;
    background-color: transparent;
    border-color: #5a55ab !important;
    svg {
      fill: #5a55ab;
      path {
        fill: #5a55ab;
      }
    }
    div {
      animation-name: none;
      border: none;
    }
  }
`

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  @media (max-width: 1200px) {
    padding: 0px 15px;
  }
`

const Tags = styled.div`
  display: flex;
  align-items: center;
`
const ShowMoreButton = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0.008em;
  color: #5a55ab;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 100px;
  float: left;
  padding: 10px 0px;
  cursor: pointer;
  i {
    font-size: 15px;
    font-weight: bold;
    position: relative;
    bottom: -2px;
    right: -2px;
  }
`

const Duration = styled.span`
  color: #556685;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 15px;
  img {
    padding-right: 6px;
  }
`

const Backlink = styled(Link)`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #5455ab;
  &::before {
    content: '<';
    color: #5455ab;
    padding-right: 8px;
  }
`

const Pathpanel = styled.div`
  width: 65%;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Infopanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const Authorinfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Authoravatar = styled.div`
  display: flex;
  align-items: center;
`

const Information = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    line-height: 22px;
    &:first-child {
      color: #8494b2;
      font-weight: normal;
      padding-right: 2px;
    }
    &:last-child {
      color: #3b4b66;
      font-weight: bold;
    }
  }
`

const InfoPanelTitle = styled.h1`
  font-weight: 900;
  font-size: 32px;
  line-height: 52px;
  color: #152540;
  text-align: left;
  padding-top: 8px;
  margin: 0;
`

const InfoPanelDescription = styled.div`
  padding-top: 16px;
  p {
    font-size: 16px;
    line-height: 26px;
    font-weight: normal;
    color: #556685;
    margin: 0 0 0px;
    text-align: left;
    display: none;
  }
  &.limit p:first-child {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &.read-more p {
    display: block;
  }
`

const ExtraInfo = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  h2 {
    color: #152540;
    margin: 0;
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
  }
`

const SkillSets = styled.div`
  text-align: left;
  margin-top: 28px;
  span {
    margin-right: 16px;
  }
`

const SkillSetsBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SkillSetsTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  color: #152540;
  padding: 24px 0;
`

const GoalSteps = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 120px;
`

const StepContainer = styled.div`
  position: relative;
  border-left: 2px solid #efeef7;
  margin-left: 30px;
  z-index: 1;
  &:first-child {
    margin-top: 100px;
    @media (max-width: 1024px) {
      margin-top: 60px;
    }
  }
  &:last-child {
    border: none !important;
  }
  & > div:last-child {
    margin-bottom: 50px;
  }
`

const StepTitle = styled.div`
  display: flex;
  align-items: flex-start;
  position: inherit;
  left: -13px;
  z-index: 2;
  top: 0px;
  img {
    position: absolute;
    z-index: 1;
  }
  h2 {
    padding: 10px 0px 10px 35px;
    font-size: 24px;
    font-weight: 900;
    line-height: 38px;
    color: #152540;
    margin: 0px;
  }
`

const StepTitleIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: white;
  span {
    color: #5a55ab;
    font-size: 24px;
    line-height: 24px;
    font-weight: 800;
    z-index: 2;
    &:first-child {
      font-size: 10px;
      line-height: 16px;
      font-weight: 700;
    }
  }
`

const BackgroundImg = styled.img`
  max-height: 400px;
  padding-left: 50px;
  object-fit: contain;
  position: absolute;
  left: 65%;
  @media (max-width: 1024px) {
    display: none;
  }
`

export default PathsList
